<template lang="pug">
#app.explorer
  section.hero.is-fullheight
    .hero-head
      Header
    .hero-body
      .container
        router-view(:key="$route.params.address")
    .hero-foot
      Footer
      cookie(v-if="!cookie")

</template>

<script>
import { mapGetters } from 'vuex'
import Cookie from '@/components/Cookie'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import detectBrowserLanguage from 'detect-browser-language'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Cookie
  },
  computed: {
    ...mapGetters(['cookie', 'locale'])
  },
  beforeMount () {
    const locales = ['en', 'ru', 'es', 'zh', 'id']
    let lang = this.locale
    if (lang && locales.includes(lang)) {
      this.$translate.setLang(lang)
    } else {
      const ussr = ['uz', 'uk', 'ar', 'md', 'tj', 'am', 'az', 'by', 'ge', 'kz', 'kg', 'lv', 'lt', 'tm']
      const browserLang = detectBrowserLanguage().substring(0, 2)
      lang = locales.includes(browserLang)
        ? browserLang
        : ussr.includes(browserLang)
          ? 'ru'
          : 'en'
      this.$store.dispatch('changeLocale', lang)
      this.$translate.setLang(lang)
    }
  },
  mounted () {
    this.$store.dispatch('getRates')
    this.$store.dispatch('getRodRates')
    this.$store.dispatch('getGlizeRates')
    this.$store.dispatch('getUmiRates')
    setInterval(() => {
      this.$store.dispatch('getRates')
      this.$store.dispatch('getRodRates')
      this.$store.dispatch('getGlizeRates')
      this.$store.dispatch('getUmiRates')
    }, 10 * 1000)
  }
}
</script>

<style>
.hero.is-fullheight .hero-body {
  align-items: flex-start;
}
@media screen and (max-width: 320px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 321px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 428px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 490px) {
  .speedometer .background {
    display: block !important;
  }
}
@media screen and (min-width: 769px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 13px;
  }
}
@media screen and (min-width: 1216px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1408px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1440px) {
  html {
    max-width: 1440px;
    font-size: 17px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1680px) {
  html {
    max-width: 1680px;
    font-size: 18px;
    margin: 0 auto;
  }
  .container {
    max-width: 1440px;
  }
}
@media screen and (min-width: 1920px) {
  html {
    max-width: 1680px;
    font-size: 18px;
  }
}
@media (min-width: 2000px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  html {
    font-size: 18px;
  }
}
</style>
