<template lang="pug">
footer#footer.footer
  .container
    .columns.is-multiline.is-flex-mobile
      .column.is-12-mobile
        .is-size-4(v-translate) Download app
        .columns.is-multiline.is-flex-mobile
          .column.is-narrow.is-narrow-mobile
            CustomIcon(url='main/app_icon.png')
          .column.is-narrow.is-narrow-mobile
            a(href="https://apps.apple.com/app/umi-wallet/id1527992117" rel="noreferer noopener" target="_blank")
              SvgIcon.badge(name="ios")
          .column.is-narrow.is-narrow-mobile
            a(:href="'https://play.google.com/store/apps/details?id=com.umi.umiwallet&hl=' + $translate.current" rel="noreferer noopener" target="_blank")
              SvgIcon.badge(name="android")
          .column.is-narrow.is-narrow-mobile
            a(href="https://appgallery.huawei.com/#/app/C104241307?sharePrepath=ag&locale=ru_RU&source=appshare&subsource=C104241307" rel="noreferer noopener" target="_blank")
              SvgIcon.badge(name="appgallery")
          .column.is-narrow.is-narrow-mobile
            a(href="https://docs.umi.top/android/umi-wallet.apk" rel="noreferer noopener" target="_blank")
              SvgIcon.badge(name="apk")

      .column.is-narrow.is-12-mobile
        .has-text-right.has-text-left-mobile
          .is-size-4(v-translate) For developers
          a.dev(href="https://umi.top/api" target="_blank") API
          a.dev(href="https://umi.top/nodes" target="_blank" v-translate) Nodes
          a.dev(href="https://gitlab.com/umitop/umid" rel="noreferer noopener" target="_blank" v-translate) Source code

    .line.is-hidden-mobile

    .links.columns.is-multiline.is-flex-mobile
      .column.is-4.is-12-mobile
        a.is-hidden-mobile( href="https://umi.top/" target="_blank" )
          SvgIcon.logo(
            title="UMI logo"
            name="logo"
          )
        br
        Socials
        .is-hidden-mobile
          br
          a(rel="noreferer noopener" target="_blank" :href="'/umi_legal_opinion.pdf'" v-translate) Legal Opinion
          a( :href='"https://umi.top"+links["Privacy"]' target="_blank" v-translate) Privacy Policy
          a( :href='"https://umi.top"+links["Terms"]' target="_blank" v-translate) Terms and Conditions
          a( :href='"https://umi.top"+links["Company"]' target="_blank" v-translate) About company
          br
          span(style="font-size: 0.75rem" v-translate) The UMI cryptocurrency and the UMI OneApp do not promise and do not guarantee any income in monetary equivalent.
          br
          br
          | {{ year }}© UMI. {{ t('All rights reserved') }}

      .column
        .columns.is-multiline.is-flex-mobile
          .column.is-3.is-12-mobile
            ul
              li.list-title(v-translate) About UMI
              li
                a( :href='"https://umi.top"+links["AboutUMI"]' target="_blank" v-translate) UMI Blockchain
              li
                a( :href='"https://umi.top"+links["Mission"]' target="_blank" v-translate) Mission
              li
                a( :href='"https://umi.top"+links["Whitepaper"]' target="_blank" v-translate) Whitepaper
              li
                a( :href='"https://umi.top"+links["Brandbook"]' target="_blank" v-translate) Brandbook

          .column.is-12-mobile
            ul
              li
                .columns.is-multiline.is-flex-mobile
                  .column.is-narrow-mobile
                    ul
                      li
                        a( :href='"https://umi.top"+links["OneApp"]' target="_blank" v-translate) What is UMI OneApp?
                      li
                        a( :href='"https://umi.top"+links["Wallet"]' target="_blank" v-translate) U-Wallet
                      li
                        a( :href='"https://umi.top"+links["Swaps"]' target="_blank" v-translate) Swap and exchanges
                      li
                        a( :href='"https://umi.top"+links["Earn"]' target="_blank" v-translate) Profit Center
                  .column.is-narrow-mobile
                    ul
                      li
                        a( :href='"https://umi.top"+links["DAO"]' target="_blank" v-translate) DAO
                      li
                        a( :href='"https://umi.top"+links["NFT"]' target="_blank" v-translate) NFT
                      li
                        a( :href='"https://umi.top"+links["Launchpad"]' target="_blank" v-translate) Launchpad
                      li
                        a( :href='"https://umi.top"+links["Solutions"]' target="_blank" v-translate) Other solutions

        .columns.is-multiline.is-flex-mobile
          .column.is-3.is-12-mobile
            ul.inline
              li.list-title(v-translate) Help
              li
                a( :href='"https://umi.top"+links["Contacts"]' target="_blank" v-translate) Contact us
          .column.is-12-mobile
            ul.inline
              li.list-title(v-translate) Tools
              li
                a( :href='"https://umi.top"+links["Volumes"]' target="_blank" v-translate) Trading volume
              li
                a( :href='"https://umi.top"+links["Liquidity"]' target="_blank" v-translate) Liquidity
              li
                a(rel="noreferer noopener" target="_blank" :href="$wallet + $translate.current" v-translate) Wallet
              li
                a(rel="noreferer noopener" target="_blank" :href="$blockchain + $translate.current" v-translate) Blockchain
              //- li
              //-   a(rel="noreferer noopener" target="_blank" :href="$blog" v-translate) Blog

          .column.is-12-mobile.is-hidden-desktop.is-hidden-tablet
              a(rel="noreferer noopener" target="_blank" :href="'/umi_legal_opinion.pdf'" v-translate) Legal Opinion
              br
              br
              a( :href='"https://umi.top"+links["Privacy"]' target="_blank" v-translate) Privacy Policy
              br
              a( :href='"https://umi.top"+links["Terms"]' target="_blank" v-translate) Terms and Conditions
              br
              a( :href='"https://umi.top"+links["Company"]' target="_blank" v-translate) About company
              br
              div(style="margin: 0.75rem 0 0;" v-translate) The UMI cryptocurrency and the UMI OneApp do not promise and do not guarantee any income in monetary equivalent.
              br
              | {{ year }}© UMI. {{ t('All rights reserved') }}
</template>

<script>
import { mapGetters } from 'vuex'
const CustomIcon = () => import('@/components/common/CustomIcon')
const Socials = () => import('@/components/Socials')

export default {
  name: 'Footer',
  components: { CustomIcon, Socials },
  data() {
    return {
      links: {
        "Privacy": "/privacy",
        "Terms": "/terms",
        "Company": "/company",
        "AboutUMI": "/blockchain",
        "Mission": "/mission",
        "Whitepaper": "/whitepaper",
        "Brandbook": "/brandbook",
        "OneApp": "/about",
        "Wallet": "/wallet",
        "Swaps": "/swaps",
        "Earn": "/earn",
        "DAO": "/dao",
        "NFT": "/nft",
        "Launchpad": "/launchpad",
        "Solutions": "/solutions",
        "Contacts": "/contacts",
        "Volumes": "/volumes",
        "Liquidity": "/liquidity"
      }
    }
  },
  computed: {
    ...mapGetters(['locale']),
    year () {
      return '2020 - ' + new Date().getFullYear()
    }
  },
}
</script>

<style scoped lang="scss">
footer {
  background: #101319;
  padding-top: 4rem;
  padding-bottom: 4rem;
  .badge {
    background: #191D26;
    box-shadow: 0px 0.25rem 0.5rem rgba(26, 26, 26, 0.2);
    border-radius: 0.25rem;
    overflow: hidden;
    height: 2rem;
    width: 6.875rem;
    margin-top: 0.5rem;
    &:hover {
      opacity: 0.85;
    }
  }
  .apk {
    color: red;
  }
  .api,
  .dev {
    background: #191D26;
    border-radius: 0.25rem;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    padding: 0.325rem 0.75rem 0.5rem;
    line-height: 1;
    color: #4787FF;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    display: inline-block;
    text-transform: uppercase;
    &:hover {
      opacity: 0.85;
    }
    .icon {
      height: 1rem;
      width: 2.5rem;
      vertical-align: middle;
    }
  }
  .line {
    margin: 1rem 0 4rem;
    height: 1px;
    background: #3B434F;
  }
  a {
    color: #ACB1BE;
    &.is-active,
    &.router-link-active,
    &:hover {
      color: #57e426;
    }
  }
  .links {
    .column:first-child {
      color: #ACB1BE;
      font-size: 0.825rem;
      a {
        display: block;
      }
      padding-right: 2rem;
    }
    .logo {
      display: block;
      height: 2.5rem;
      width: 5.875rem;
      margin-bottom: 1.5rem;
    }
    ul {
      li {
        font-size: 1.125rem;
        line-height: 154%;
        &.list-title {
          font-family: "PT Mono";
          font-weight: normal;
          font-size: 1.5rem;
          color: #FFFFFF;
          margin-bottom: 0.5rem;
        }
      }
      &.inline li:not(:first-child) {
        display: inline-block;
        margin-right: 3rem;
        @media screen and (min-width: 769px) {
          margin-right: 1.75rem;
        }
      }
    }
  }
}
</style>
