import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
const Explorer = import('../views/Explorer')

const langs = ['ru', 'es', 'zh', 'en', 'id']
Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang(en|ru|es|zh|id)?',
    component: () => Explorer,
    props: true,
    beforeEnter (to, from, next) {
      const lang = to.params.lang
      if (lang && langs.includes(lang)) {
        if (store.state.locale !== lang) {
          store.dispatch('changeLocale', lang)
        }
        return next()
      }
      return next()
    },
    children: [
      {
        path: 'status',
        alias: '/',
        name: 'Status'
      },
      {
        path: 'rod',
        name: 'Rod'
      },
      {
        path: 'rod-structures',
        name: 'ROD Structures'
      },
      {
        path: 'glize',
        name: 'Glize'
      },
      {
        path: 'umi',
        name: 'Umi'
      },
      {
        path: 'blocks',
        name: 'Blocks'
      },
      {
        path: 'block/:height',
        name: 'Block',
        props: true
      },
      {
        path: 'transactions',
        name: 'Transactions'
      },
      {
        path: 'burned',
        name: 'Burned'
      },
      {
        path: 'transaction/:transaction',
        name: 'Transaction',
        props: true
      },
      // {
      //   path: 'prices',
      //   name: 'Prices'
      // },
      {
        path: 'github',
        redirect: 'gitlab'
      },
      {
        path: 'gitlab',
        name: 'GitLab'
      },
      {
        path: 'addresses',
        name: 'Addresses'
      },
      {
        path: 'address/:address',
        name: 'Address'
      },
      {
        path: '*',
        redirect: '/status'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  beforeEach: (to, from, next) => {
    let isDelegated = false

    for (let matched = to.matched || [], i = matched.length; i--;) {
      const route = matched[i]

      if (route.meta.beforeEach) {
        isDelegated = true
        route.meta.beforeEach(to, from, next)
      }
    }

    !isDelegated && next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.params.lang) {
    const lang = to.params.lang
    if (lang && langs.includes(lang) && store.state.locale !== lang) {
      store.dispatch('changeLocale', lang)
    }
  }
  next()
})

export default router
