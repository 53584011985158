import api from '@/api/api'

export default {
  getStatus () {
    return api().get('status')
  },
  getRodStatus () {
    return api().get('rod-status')
  },
  getBlocks (params) {
    return api().get('blocks', { params })
  },
  getBlocksStats () {
    return api().get('blocks/stats')
  },
  getBlock (height) {
    return api().get('blocks/' + height)
  },
  getBlockTransactions (height, params = {}) {
    return api().get('blocks/' + height + '/transactions', { params })
  },

  getAddressStats () {
    return api().get('address/stats')
  },
  getAddress (address) {
    return api().get('address/' + address)
  },
  getAddressTransactions (address, params, filter = 'any') {
    if (filter === 'sended') {
      return api().get('address/' + address + '/transactions/sended', {
        params
      })
    }
    if (filter === 'received') {
      return api().get('address/' + address + '/transactions/received', {
        params
      })
    }
    if (filter === 'filtered') {
      return api().get('address/' + address + '/transactions/filtered', {
        params
      })
    }
    return api().get('address/' + address + '/transactions', { params })
  },
  getAddressSended (address, params) {
    return api().get('address/' + address + '/transactions/sended', { params })
  },
  getAddressReceived (address, params) {
    return api().get('address/' + address + '/transactions/received', {
      params
    })
  },

  getTransactionsStats () {
    return api().get('transactions/stats')
  },
  getRodTransactionsStats () {
    return api().get('transactions/rod-stats')
  },
  getGlizeTransactionsStats () {
    return api().get('transactions/glz-stats')
  },
  getTransactions (params) {
    return api().get('transactions', { params })
  },
  getTransaction (hash, block) {
    return api().get('transactions/' + hash, { params: { block: block } })
  },
  getStructures () {
    return api().get('structures')
  },
  getGithub () {
    return api().get('github')
  },
  getVolumes () {
    return api().get('volumesStats')
  },
  getRodVolumes () {
    return api().get('volumesStats-rod')
  },
  getGlizeVolumes () {
    return api().get('volumesStats-glz')
  },
  getLiquid () {
    return api().get('liquidity')
  },
  getRodLiquid () {
    return api().get('liquidity-rod')
  },
  getGlizeLiquid () {
    return api().get('liquidity-glz')
  },
  getRates () {
    return api().get('rates')
  },
  search (keyword) {
    return api().get('search/' + keyword)
  }
}
