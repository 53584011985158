import axios from 'axios'
// import { SnackbarProgrammatic as Snackbar } from 'buefy'

axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export default () => {
  const axiosInstance = axios.create({
    baseURL: (window.location.host.indexOf('testnet') > -1)
      ? 'https://stats-testnet.umi.top'
      : window.location.host.indexOf('localhost') > -1
        // ? 'http://localhost:3000'
        ? 'https://stats-testnet.umi.top'
        : 'https://stats.umi.top'
  })

  const isHandlerEnabled = (config = {}) =>
    !(
      Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') &&
      !config.handlerEnabled
    )

  const errorHandler = e => {
    if (isHandlerEnabled(e.config)) {
      console.log(e)
    }
    return Promise.reject(e)
  }
  const successHandler = response => {
    return response
  }

  axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  )

  return axiosInstance
}
