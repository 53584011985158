<template lang="pug">
#app.explorer
  section.hero.is-fullheight
    .hero-head
      Header
    .hero-body
      .container
        router-view(:key="$route.params.address")
        .network
          .list
            .list-item( v-for='(name, url) in networks' v-if="url !== origin"  )
              a( :href="url" ) {{ name }}
          .current
            p( v-translate ) Network
            .is-flex.is-align-items-center.is-justify-content-space-between
              span {{ networks[origin] }}
              SvgIcon.icon( name="chevron-bottom" )
    .hero-foot
      Footer
      cookie(v-if="!cookie")

</template>

<script>
import { mapGetters } from 'vuex'
import Cookie from '@/components/Cookie'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import detectBrowserLanguage from 'detect-browser-language'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Cookie
  },
  data() {
    return {
      origin: window.location.origin,
      networks: {
        'https://blockchain.umi.top': 'Mainnet Legend',
        'https://blockchain-testnet.umi.top': 'Testnet Legend',
        'https://blockchain-v1.umi.top': 'Mainnet V1',
        'https://blockchain-testnet-v1.umi.top': 'Testnet V1',
      }
    }
  },
  computed: {
    ...mapGetters(['cookie', 'locale'])
  },
  beforeMount () {
    const locales = ['en', 'ru', 'es', 'zh', 'id']
    let lang = this.locale
    if (lang && locales.includes(lang)) {
      this.$translate.setLang(lang)
    } else {
      const ussr = ['uz', 'uk', 'ar', 'md', 'tj', 'am', 'az', 'by', 'ge', 'kz', 'kg', 'lv', 'lt', 'tm']
      const browserLang = detectBrowserLanguage().substring(0, 2)
      lang = locales.includes(browserLang)
        ? browserLang
        : ussr.includes(browserLang)
          ? 'ru'
          : 'en'
      this.$store.dispatch('changeLocale', lang)
      this.$translate.setLang(lang)
    }
  },
  mounted () {
    this.$store.dispatch('getRates')
    this.$store.dispatch('getRodRates')
    this.$store.dispatch('getGlizeRates')
    this.$store.dispatch('getUmiRates')
    setInterval(() => {
      this.$store.dispatch('getRates')
      this.$store.dispatch('getRodRates')
      this.$store.dispatch('getGlizeRates')
      this.$store.dispatch('getUmiRates')
    }, 10 * 1000)
  }
}
</script>

<style>
.hero.is-fullheight .hero-body {
  align-items: flex-start;
}
.network {
  position: fixed;
  z-index: 4;
  bottom: 0.75rem;
  left: 0.75rem;
  box-shadow: 0px 12px 19px 0px #0000001A;
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  background: linear-gradient(91.82deg, #13161B -61.91%, #18202B 98.46%);
  font-size: 0.75rem;
  color: #57e426;
  cursor: pointer;
  .current {
    padding: 10px 12px;
    &> div {
      width: 100%;
    }
    &> p {
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      opacity: 0.8;
    }
    span {
      font-size: 14px;
      font-weight: 500;
    }
    .icon {
      width: 18px;
      height: 18px;
      transform: rotate(180deg);
      margin-left: 5px;
    }
  }

  .list {
    display: none;
  }

  a {
    width: 100%;
    padding: 13px 15px;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #fff;
    cursor: pointer;
    display: block;
    &:hover {
      background: #FFFFFF0A;
    }
  }
}

.network:hover {
  .list {
    display: block;
  }
}
@media screen and (max-width: 320px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 321px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 428px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 490px) {
  .speedometer .background {
    display: block !important;
  }
}
@media screen and (min-width: 769px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 13px;
  }
}
@media screen and (min-width: 1216px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1408px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1440px) {
  html {
    max-width: 1440px;
    font-size: 17px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1680px) {
  html {
    max-width: 1680px;
    font-size: 18px;
    margin: 0 auto;
  }
  .container {
    max-width: 1440px;
  }
}
@media screen and (min-width: 1920px) {
  html {
    max-width: 1680px;
    font-size: 18px;
  }
}
@media (min-width: 2000px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  html {
    font-size: 18px;
  }
}
</style>
