import axios from 'axios'
// import { SnackbarProgrammatic as Snackbar } from 'buefy'

axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

export default () => {
  let API = ''

  if (window.location.host.startsWith('blockchain-testnet-v1.')) {
    API = 'https://stats-testnet.umi.top/'
  }
  else if (window.location.host.startsWith('blockchain-v1.')) {
    API = 'https://stats.umi.top/'
  }
  else if (window.location.host.startsWith('localhost')) {
    API = 'http://localhost:4000/'
  }
  else if (window.location.host.startsWith('blockchain.')) {
    API = 'https://stats-legend.umi.top/'
  }
  else if (window.location.host.startsWith('blockchain-testnet.')) {
    API = 'https://stats-testnet-legend.umi.top/'
  }

  const axiosInstance = axios.create({
    baseURL: API
  })

  const isHandlerEnabled = (config = {}) =>
    !(
      Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') &&
      !config.handlerEnabled
    )

  const errorHandler = e => {
    if (isHandlerEnabled(e.config)) {
      console.log(e)
    }
    return Promise.reject(e)
  }
  const successHandler = response => {
    return response
  }

  axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  )

  return axiosInstance
}
