export default {
  en: {
    'Staking per month': 'Staking<br>per month',
    red_stage: 'Red',
    blue_stage: 'Blue',
    green_stage: 'Green',
    Burning: 'Burned',
    'Burned in network': 'Burned in network',
    'Burn transactions': 'Burn transactions',
    'Burned total': 'Burned total',
    'Burned today': 'Burned today'
  },
  ru: {
    Manifest: 'Манифест',
    // Common
    'Copied to clipboard!': 'Скопировано в буфер обмена!',
    sec: 'сек',
    'Copy tx': 'Скопировать tx',
    'Copy block height': 'Скопировать высоту блока',
    'Open in new window': 'Открыть в новом окне',
    Amount: 'Сумма',
    Height: 'Высота',
    Transaction: 'Транзакция',
    'Nothing found!': 'Ничего не найдено!',
    'Page:': 'Страница:',

    // Cookie
    'We use cookies to provide you the most comfortable use of the site.':
      'Мы используем куки, чтобы обеспечить вам максимально комфортное пользование сайтом.',
    'Please see our <a target="_blank" href="https://umi.top/privacy">Privacy Policy</a> for more information.':
      'Пожалуйста, ознакомьтесь с <a target="_blank" href="https://umi.top/ru/privacy">Политикой конфиденциальности</a> для получения подробной информации.',
    'I accept': 'Хорошо',

    // Header
    'Search for address, tx, block': 'Поиск по адресу, tx, и др.',

    // Footer
    'Privacy Policy': 'Политика конфиденциальности',
    'Terms and Conditions': 'Условия и положения',
    'All rights reserved': 'Все права защищены',
    'About UMI': 'О UMI',
    'Features/WP': 'Особенности/WP',
    News: 'Новости',
    Blog: 'Блог',
    Grants: 'Гранты',
    'Brand Book': 'Брендбук',
    Presentation: 'Презентация',
    Info: 'Информация',
    Mission: 'Миссия',
    Security: 'Безопасность',
    Earning: 'Заработок',
    Technology: 'Технология',
    Speed: 'Скорость',
    'Trading volume,\nCommon': 'Объем торгов,\nОбщий',
    'Trading volume,\nP2P': 'Объем торгов,\nP2P',
    'Trading volume,\nExchange': 'Объем торгов,\nБиржа',
    'Liquidity': 'Ликвидность',
    'Liquidity,\nCommon': 'Ликвидность,\nОбщая',
    'Liquidity, P2P': 'Ликвидность, P2P',
    'Liquidity, Exchange': 'Ликвидность, Биржа',
    buy: 'купить',
    sell: 'продать',
    Tools: 'Инструменты',
    Blockchain: 'Блокчейн',
    Wallet: 'Кошелек',
    'Source code': 'Исходный код',
    Nodes: 'Ноды',
    'For organizators': 'Организаторам',
    Help: 'Поддержка',
    FAQ: 'FAQ',
    Guide: 'Гайд',
    'Contact us': 'Связаться',
    Staking: 'Стейкинг',
    Balance: 'Баланс',
    Address: 'Адрес',

    // Explorer.vue
    'Block time': 'Время блока',
    'Transactions in block': 'Транзакций в блоке',
    'Transactions per min': 'Транзакций за мин',
    'Transactions per hour': 'Транзакций за час',
    'Daily transactions': 'Транзакций за сутки',
    Blocks: 'Блоки',
    'Create structure fee': 'Цена создания структуры',
    Transactions: 'Транзакции',
    Price: 'Курс',
    GitLab: 'GitLab',
    Addresses: 'Адреса',

    'Total coins': 'Всего монет',
    'Total tokens': 'Всего токенов',
    'In structures': 'В структурах',
    'On the exchange': 'На бирже',
    'At trading addresses': 'На торговых адресах',
    'Initial Emission': 'Начальная эмиссия',
    'Staking Percentage': 'Процент стейкинга',
    'Number of Structures': 'Количество структур',
    More: 'Подробнее',

    'Total transactions': 'Всего транзакций',
    'Block number': 'Номер блока',
    'Trading Volume UMI': 'Объем торгов UMI',
    'Trading Volume ROD': 'Объем торгов ROD',
    Capitalization: 'Капитализация',

    'Transactions per second': 'Транзакций в секунду',
    Now: 'Сейчас',
    Maximum: 'Максимум',

    // Addresses
    'Top 100 richest addresses': 'Топ 100 богатейших адресов',
    'Addresses are richer than 1/100/1,000/1,0000 USD': 'Адреса богаче 1/100/1,000/10,000 USD',
    'Top richest 10/100/1,000/10,000 addresses': 'Топ богатейших 10/100/1,000/10,000 адресов',
    'Active addresses in 24 hours': 'Активные адреса за 24 часа',
    'Number of UMI addresses': 'Количество UMI адресов',
    'Number of addresses in structures': 'Количество адресов в структурах',
    'Genesis address': 'Genesis адрес',

    // Blocks
    'Number of blocks': 'Количество блоков',
    'Last block time': 'Время последнего блока',
    'Since the last block has passed': 'С момента последнего блока прошло',
    'Transactions in block (24 hours)': 'Транзакций в блоке (за 24 часа)',
    'Active addresses (24 hours)': 'Активные адреса (за 24 часа)',
    'Blocks per hour (24 hours)': 'Блоков за час (за 24 часа)',
    Timestamp: 'Время создания',

    // Github
    'The main GitLab with documentation and SDK':
      'Основной GitLab c документацией и SDK',
    Stars: 'Количество звезд',
    'Last update': 'Последнее обновление',
    Repositories: 'Репозиториев',
    Followers: 'Подписчиков',

    // Price
    'The price of the dollar in UMI': 'Цена доллара в UMI',
    'Trading volume on exchanges in 24 hours': 'Объем торгов на биржах за 24 ч.',
    'Maximum price for 24 hours': 'Максимальная цена за 24 ч.',
    'Minimum price for 24 hours': 'Минимальная цена за 24 ч.',
    'P2P trading volume on exchanges in 24 hours': 'Объем торгов P2P на биржах за 24 ч.',

    // Transactions
    '24 hours transaction': 'Транзакций за 24 часа',
    'Transactions per hour (average)': 'Транзакций за час (среднее)',
    'Transferred in 24 hours': 'Переведено за 24 часа',
    'Transferred per hour (average)': 'Переведено за час (среднее)',
    '100 largest transactions in the last 24 hours':
      '100 крупнейших транзакций за последние 24 часа',

    // Address
    'UMI address': 'UMI адрес',
    'Total sent': 'Всего переведено',
    'Total received': 'Всего получено',
    All: 'Все',
    Sent: 'Отправленные',
    Received: 'Полученные',

    // Block
    'Block height': 'Высота блока',
    'Block not yet created': 'Блок еще не создан',
    'Previous block': 'Предыдущий блок',
    'Next block': 'Следующий блок',
    Version: 'Версия',
    'Total amount': 'Сумма транзакций',
    'Total fee': 'Сумма комиссий',

    // Structures
    Pools: 'Пулы',
    'UMI structures': 'Структуры UMI',
    'Staking per day': 'Стейкинг в день',
    'Staking per month': 'Стейкинг<br>в месяц',
    'Structure site': 'На сайт структуры',
    'per day': 'в день',
    'per month': 'в месяц',
    Fee: 'Комиссия',
    Coins: 'Количество монет',
    Members: 'Участников',
    Wallets: 'Кошельков',
    'Structure Income': 'Вознаграждение структуры',
    'The biggest crypto-community with more than 400,000 participants.':
      'Крупнейшее криптосообщество с более чем 400 000 участников!',
    'High-tech platform for automatic staking of UMI cryptocurrency up to 5669% per year.': 'Высокотехнологичная платформа для автоматического стейкинга криптовалюты UMI до 5669% в год!',

    // Trx Service
    Block: 'Блок',
    'Another operation': 'Другая операция',
    'Set staking percent': 'Изменен процент дивидендов',
    'Set bonuses percent': 'Изменен процент бонусов',
    'Set profit address': 'Назначен адрес профита',
    'Set fee address': 'Назначен адрес комиссий',
    'Activate transit address': 'Активирован транзитный адрес',
    'Deactivate transit address': 'Деактивирован транзитный адрес',
    'Create new Pool': 'Создание новой структуры',
    'Update Pool name': 'Изменения названия пула',
    'Update structure': 'Изменение параметров структуры',
    'Profit Wallet': 'Адрес профита',
    'Fee Wallet': 'Адрес комиссий',
    'Transit Wallet': 'Транзитный адрес',
    'Transit Wallets': 'Транзитные адреса',
    View: 'Смотреть',
    'All transactions without structure rewards': 'Все транзакции без структурных наград',

    'Current emission': 'Текущая эмиссия',
    'End emission': 'Конечная эмиссия',
    'Trading addresses': 'Торговых адресов',
    'Staking addresses': 'Стейкинг адресов',
    'In staking': 'В стейкинге',
    red_stage: 'Красный',
    blue_stage: 'Синий',
    green_stage: 'Зеленый',
    'Staking stage': 'Этап стейкинга',
    'Staking percent': 'Процент стейкинга',
    Burned: 'Сожжено',
    Burning: 'Сжигание',
    'Burned in network': 'Сжигания в сети UMI',
    'Burn transactions': 'Транзакций сжигания',
    'Burned total': 'Всего сожжено',
    'Burned today': 'Сожжено сегодня',
    'What is UMI OneApp?': 'Что такое UMI OneApp?',
    'Profit Center': 'Центр заработка',
    'Open OneApp': 'Открыть OneApp',
    'Swap and exchanges': 'Децентрализованные обмены',
    Earn: 'Заработок',
    Launchpad: 'Сбор средств',
    'Other solutions': 'Другие решения',
    Brandbook: 'Брендбук',
    'Legal Opinion': 'Юридическое заключение',
    'Minting tokens': 'Чеканка монет',
    'Mint NFT': 'Создание NFT',
    Name: 'Название',
    Description: 'Описание',
    Author: 'Автор',
    'Content Type': 'Формат',
    'NFT collection': 'NFT коллекция',
    'No meta data': 'Нет meta-данных',
    "Preview did't load": 'Превью не доступно',
    'Custom NFT content': 'Другой контент',
    'Transfer NFT': 'Трансфер NFT'
  },
  es: {
    Manifest: 'Manifiesto',
    'Copied to clipboard!': '¡Copiado al portapapeles!',
    sec: 'segundo',
    'Copy tx': 'Copiar tx',
    'Copy block height': 'Copiar altura del bloque',
    'Open in new window': 'Abrir en Nueva ventana',
    Amount: 'Cantidad',
    Height: 'Altura',
    Transaction: 'Transacción',
    'Nothing found!': '¡Nada Encontrado!',
    'Page:': 'Página:',
    'We use cookies to provide you the most comfortable use of the site.': 'Usamos cookies para brindarle el uso más cómodo del sitio.',
    'Please see our <a target="_blank" href="https://umi.top/privacy">Privacy Policy</a> for more information.': 'Consulte nuestra <a target="_blank" href="https://umi.top/es/privacy">Política de privacidad</a> para obtener más información.',
    'I accept': 'Acepto',
    'Search for address, tx, block': 'Buscar dirección, tx, bloque',
    'Privacy Policy': 'Política de privacidad',
    'Terms and Conditions': 'Términos y Condiciones',
    'All rights reserved': 'Todos los derechos reservados',
    'About UMI': 'Sobre UMI',
    'Features/WP': 'Características/WP',
    News: 'Noticias',
    Blog: 'Blog',
    Grants: 'Subsidios',
    'Brand Book': 'Libro de marca',
    Presentation: 'Presentación',
    Info: 'Información',
    Mission: 'Misión',
    Security: 'Seguridad',
    Earning: 'Ganador',
    Technology: 'Tecnología',
    Speed: 'Velocidad',
    'Trading volume': 'Volumen de comercio',
    Liquidity: 'Liquidez',
    buy: 'comprar',
    sell: 'vender',
    Tools: 'Herramientas',
    Blockchain: 'Blockchain',
    Wallet: 'Billetera',
    'Source code': 'Código fuente',
    Nodes: 'Nodos',
    'For organizators': 'Para organizadores',
    Help: 'Ayuda',
    FAQ: 'Preguntas más frecuentes',
    Guide: 'Guía',
    'Contact us': 'Contáctenos',
    Staking: 'Replanteo',
    Balance: 'Equilibrar',
    Address: 'Habla a',
    'Block time': 'Tiempo de bloque',
    'Transactions in block': 'Transacciones en bloque',
    'Transactions per min': 'Transacciones por minuto',
    'Transactions per hour': 'Transacciones por hora',
    'Daily transactions': 'Transacciones diarias',
    Blocks: 'Bloques',
    Transactions: 'Actas',
    Price: 'Curso',
    GitLab: 'GitLab',
    Addresses: 'Direcciones',
    'Total coins': 'Total de monedas',
    'In structures': 'En estructuras',
    'Initial Emission': 'Emisión inicial',
    'Staking Percentage': 'Porcentaje de apuesta',
    'Number of Structures': 'Número de estructuras',
    More: 'Más',
    'Total transactions': 'Transacciones totales',
    'Block number': 'Número de bloque',
    'Trading Volume UMI': 'Comercio UMI',
    Capitalization: 'Capitalización',
    'Transactions per second': 'Transacciones por segundo',
    Now: 'Ahora',
    Maximum: 'Máximo',
    'Top 100 richest addresses': 'Las 100 direcciones más ricas',
    'Active addresses in 24 hours': 'Direcciones activas en 24 horas',
    'Number of UMI addresses': 'Número de direcciones UMI',
    'Number of addresses in structures': 'Número de direcciones en estructuras',
    'Genesis address': 'Dirección de Génesis',
    'Number of blocks': 'Numero de bloques',
    'Last block time': 'Hora del último bloque',
    'Since the last block has passed': 'Desde que pasó el último bloque',
    'Transactions in block (24 hours)': 'Transacciones en bloque (24 horas)',
    'Active addresses (24 hours)': 'Direcciones activas (24 horas)',
    'Blocks per hour (24 hours)': 'Bloques por hora (24 horas)',
    Timestamp: 'Marca de tiempo',
    'The main GitLab with documentation and SDK': 'El GitLab principal con documentación y SDK',
    Stars: 'Estrellas',
    'Last update': 'Última actualización',
    Repositories: 'Repositorios',
    Followers: 'Seguidores',
    'The price of the dollar in UMI': 'El precio del dólar en UMI',
    'Trading volume on exchanges in 24 hours': 'Volumen de operaciones en intercambios en 24 horas',
    'Maximum price for 24 hours': 'Precio máximo por 24 horas',
    'Minimum price for 24 hours': 'Precio mínimo por 24 horas',
    'P2P trading volume on exchanges in 24 hours': 'Volumen de operaciones P2P en intercambios en 24 horas',
    '24 hours transaction': 'Transacción de 24 horas',
    'Transactions per hour (average)': 'Transacciones por hora (promedio)',
    'Transferred in 24 hours': 'Transferido en 24 horas',
    'Transferred per hour (average)': 'Transferidos por hora (promedio)',
    '100 largest transactions in the last 24 hours': '100 transacciones más grandes en las últimas 24 horas',
    'UMI address': 'Dirección UMI',
    'Total sent': 'Total enviado',
    'Total received': 'Total recibido',
    All: 'Todas',
    Sent: 'Expedido',
    Received: 'Recibido',
    'Block height': 'Altura del bloque',
    'Block not yet created': 'Bloque aún no creado',
    'Previous block': 'Bloque anterior',
    'Next block': 'Siguiente bloque',
    Version: 'Versión',
    'Total amount': 'Cantidad total',
    'Total fee': 'Costo total',
    Pools: 'Quinielas',
    'UMI structures': 'Estructuras UMI',
    'Staking per day': 'Staking por día',
    'Staking per month': 'Staking<br>por mes',
    'Structure site': 'Sitio de estructura',
    'per day': 'por día',
    'per month': 'por mes',
    Fee: 'Cuota',
    Coins: 'Monedas',
    Members: 'Miembros',
    Wallets: 'Carteras',
    'Structure Income': 'Renta de estructura',
    'The biggest crypto-community with more than 400,000 participants.': 'La mayor comunidad criptográfica con más de 400.000 participantes.',
    'High-tech platform for automatic staking of UMI cryptocurrency up to 5669% per year.': 'Plataforma de alta tecnología para la participación automática de criptomonedas UMI hasta un 5669% por año.',
    Block: 'Bloque',
    'Another operation': 'Otra operacion',
    'Set staking percent': 'Establecer el porcentaje de participación',
    'Set bonuses percent': 'Establecer porcentaje de bonificaciones',
    'Set profit address': 'Establecer dirección de ganancias',
    'Set fee address': 'Establecer dirección de tarifa',
    'Activate transit address': 'Activar dirección de tránsito',
    'Deactivate transit address': 'Desactivar la dirección de tránsito',
    'Create new Pool': 'Crear nueva estructura',
    'Update structure': 'Estructura de actualización',
    'Profit Wallet': 'Billetera de ganancias',
    'Fee Wallet': 'Monedero de tarifa',
    'Transit Wallet': 'Billetera Transit',
    'Transit Wallets': 'Carteras de tránsito',
    View: 'Vista',
    'All transactions without structure rewards': 'Todas las transacciones sin recompensas de estructura.',
    'Current emission': 'Emisión actual',
    'End emission': 'Emisión final',
    'Trading addresses': 'Direcciones de negociación',
    'Staking addresses': 'Mantenerse direcciones',
    'In staking': 'En suspender',
    red_stage: 'Roja',
    blue_stage: 'Azul',
    green_stage: 'Verde',
    'Staking stage': 'Escenario de la esclavitud',
    'Staking percent': 'Porcentaje de la apuesta',
    Burned: 'Quemada',
    Burning: 'Quemada',
    'Burned in network': 'Quemado en la red',
    'Burn transactions': 'Transacciones de grabación',
    'Burned total': 'Total quemado',
    'Burned today': 'Quemado hoy',
    'What is UMI OneApp?': '¿Qué es UMI OneApp?',
    'Profit Center': 'Centro de Beneficios',
    'Swap and exchanges': 'DEX',
    Earn: 'Ganar',
    Launchpad: 'Launchpad',
    'Other solutions': 'Otras soluciones',
    Brandbook: 'Libro de marca',
    'Legal Opinion': 'Opinión legal',
    'Minting tokens': 'Tokens de acuñación',
    'Mint NFT': 'Como NFT',
    Name: 'Nombre',
    Description: 'Descripción',
    Author: 'Autora',
    'Content Type': 'Tipo de contenido',
    'NFT collection': 'Colección NFT',
    'No meta data': 'No hay datos metáticos',
    "Preview did't load": 'La vista previa no carga',
    'Custom NFT content': 'Contenido de NFT personalizado',
    'Transfer NFT': 'Transferir NFT'
  },
  zh: {
    Manifest: '宣言',
    'Copied to clipboard!': '复制到剪贴板！',
    sec: '秒',
    'Copy tx': '复制TX',
    'Copy block height': '复印块高度',
    'Open in new window': '在新窗口中打开',
    Amount: '量',
    Height: '高度',
    Transaction: '交易',
    'Nothing found!': '什么都没找到！',
    'Page:': '页：',
    'We use cookies to provide you the most comfortable use of the site.': '我们使用cookie为您提供最舒适的网站使用。',
    'Please see our <a target="_blank" href="https://umi.top/privacy">Privacy Policy</a> for more information.': '有关更多信息，请参阅我们的<a target="_blank" href="https://umi.top/zh/privacy">隐私政策</a>。',
    'I accept': '我接受',
    'Search for address, tx, block': '地址搜索，TX，等',
    'Privacy Policy': '隐私政策',
    'Terms and Conditions': '条款和条件',
    'All rights reserved': '版权所有',
    'About UMI': '关于UMI',
    'Features/WP': '功能/白皮书',
    Onepager: '单页',
    Yellowpaper: '黄纸',
    Whitepaper: '白皮书',
    News: '新闻',
    Blog: '博客',
    Grants: '赠款',
    'Brand Book': '品牌书',
    Presentation: '介绍',
    Info: '信息',
    Mission: '使命',
    Security: '安全',
    Earning: '收益',
    Technology: '技术',
    Speed: '速度',
    'Trading volume': '交易量',
    Liquidity: '流动性',
    buy: '买入',
    sell: '卖出',
    Tools: '工具',
    Blockchain: '区块链',
    Wallet: '钱包',
    'Source code': '源代码',
    Nodes: '节点数',
    'For organizators': '组织者',
    Help: '技术支持',
    FAQ: '常见问题',
    Guide: '指南',
    'Contact us': '联系我们',
    Staking: '质押',
    Balance: '平衡',
    Address: '地址',
    'Block time': '区块时间',
    'Transactions in block': '区块成交量',
    'Transactions per min': '每分钟成交量',
    'Transactions per hour': '每小时成交量',
    'Daily transactions': '每日成交量',
    Blocks: '区块',
    Transactions: '成交量',
    Price: '课程',
    GitLab: 'GitLab',
    Addresses: '地址',
    'Total coins': '代币总数',
    'In structures': '在结构中',
    'Initial Emission': '初始发行量',
    'Staking Percentage': '质押比例',
    'Number of Structures': '结构数量',
    More: '更多',
    'Total transactions': '总成交量',
    'Block number': '区块',
    'Trading Volume UMI': '交易量UMI',
    Capitalization: '市值',
    'Transactions per second': '每秒成交量',
    Now: '现在',
    Maximum: '最多',
    'Top 100 richest addresses': '前100名最富有的地址',
    'Active addresses in 24 hours': '24小时内有效地址',
    'Number of UMI addresses': 'UMI地址数',
    'Number of addresses in structures': '结构中的地址数',
    'Genesis address': '创世地址',
    'Number of blocks': '区块数量',
    'Last block time': '最后区块时间',
    'Since the last block has passed': '自最后区块以来已过去的时间',
    'Transactions in block (24 hours)': '区块交易数量（24小时）',
    'Active addresses (24 hours)': '有效地址（24小时）',
    'Blocks per hour (24 hours)': '小时内区块量（24小时）',
    Timestamp: '成交时间',
    'The main GitLab with documentation and SDK': '带有文档和SDK的主要GitLab',
    Stars: '星星',
    'Last update': '最后更新',
    Repositories: '储存库',
    Followers: '追随者',
    'The price of the dollar in UMI': 'UMI对美元价格',
    'Trading volume on exchanges in 24 hours': '24小时交易平台交易量',
    'Maximum price for 24 hours': '24小时最高价格',
    'Minimum price for 24 hours': '24小时最低价格',
    'P2P trading volume on exchanges in 24 hours': '24 P2P交易平台交易量',
    '24 hours transaction': '24小时成交量',
    'Transactions per hour (average)': '每小时平均成交量',
    'Transferred in 24 hours': '24小时转账金额',
    'Transferred per hour (average)': '每小时转账平均金额',
    '100 largest transactions in the last 24 hours': '最近24小时内100最大交易',
    'UMI address': 'UMI地址',
    'Total sent': '发送总数',
    'Total received': '收到的总数',
    All: '所有',
    Sent: '已发送',
    Received: '已收到',
    'Block height': '块高',
    'Block not yet created': '尚未建立的区块',
    'Previous block': '上一个区块',
    'Next block': '下一块',
    Version: '版',
    'Total amount': '总金额',
    'Total fee': '总费用',
    Pools: '泳池',
    'UMI structures': 'UMI结构',
    'Staking per day': '每天放样',
    'Staking per month': '每月放样',
    'Structure site': '结构现场',
    'per day': '每天',
    'per month': '每月',
    Fee: '费用',
    Coins: '硬币',
    Members: '会员',
    Wallets: '錢包',
    'Structure Income': '结构收益',
    'The biggest crypto-community with more than 400,000 participants.': '最大的加密货币社区，已吸引了超过400 000名会员。',
    'High-tech platform for automatic staking of UMI cryptocurrency up to 5669% per year.': '高科技平台，UMI加密货币自动质押年收益高达5669％。',
    Block: '块',
    'Another operation': '另一操作',
    'Set staking percent': '固定百分比',
    'Set bonuses percent': '设定奖金百分比',
    'Set profit address': '设定利润地址',
    'Set fee address': '设定费用地址',
    'Activate transit address': '激活公交地址',
    'Deactivate transit address': '停用转帐地址',
    'Create new Pool': '建立新结构',
    'Update structure': '更新结构',
    'Profit Wallet': '收益钱包',
    'Fee Wallet': '手续费钱包',
    'Transit Wallet': '中专钱包',
    'Transit Wallets': '过境钱包',
    View: '看法',
    'All transactions without structure rewards': '所有沒有結構獎勵的交易',
    'Current emission': '当前排放',
    'End emission': '最终排放',
    'Trading addresses': '交易地址',
    'Staking addresses': '绑定地址',
    'In staking': '在烤箱中',
    red_stage: '红色的',
    blue_stage: '蓝色',
    green_stage: '绿',
    'Staking stage': '铆接舞台',
    'Staking percent': '放置百分比',
    Burned: '烧毁',
    Burning: '烧毁',
    'Burned in network': '在网络中烧毁',
    'Burn transactions': '燃烧交易',
    'Burned total': '总计烧了',
    'Burned today': '今天烧了',
    'What is UMI OneApp?': '什么是UMI OneApp',
    'Profit Center': '收益中心',
    'Swap and exchanges': 'DEX',
    Earn: '赚',
    Launchpad: '发射台',
    'Other solutions': '其他解决方案',
    Brandbook: '品牌书籍',
    'Legal Opinion': '法律意见',
    'Minting tokens': '薄荷令牌',
    'Mint NFT': '像NFT',
    Name: '姓名',
    Description: '描述',
    Author: '作者',
    'Content Type': '内容类型',
    'NFT collection': 'NFT系列',
    'No meta data': '没有元数据',
    "Preview did't load": '预览不加载',
    'Custom NFT content': '自定义NFT内容',
    'Transfer NFT': '转移 NFT'
  },
  id: {
    Manifest: 'Manifest',
    'Copied to clipboard!': 'Disalin ke papan klip!',
    sec: 'detik',
    'Copy tx': 'Salin tx',
    'Copy block height': 'Salin tinggi blok',
    'Open in new window': 'Buka di jendela baru',
    Amount: 'Jumlah',
    Height: 'Tinggi',
    Transaction: 'Transaksi',
    'Nothing found!': 'Tidak ada yang ditemukan!',
    'Page:': 'Halaman:',
    'We use cookies to provide you the most comfortable use of the site.': 'Kami menggunakan cookie untuk memberi Anda penggunaan situs yang paling nyaman.',
    'Please see our <a target="_blank" href="https://umi.top/privacy">Privacy Policy</a> for more information.': 'Silakan lihat <a target="_blank" href="https://umi.top/id/privacy">Kebijakan Privasi</a> kami untuk informasi lebih lanjut.',
    'I accept': 'saya menerima',
    'Search for address, tx, block': 'Cari alamat, tx, blok',
    'Privacy Policy': 'Kebijakan pribadi',
    'Terms and Conditions': 'Syarat dan ketentuan',
    'All rights reserved': 'Seluruh hak cipta',
    'About UMI': 'Tentang UMI',
    'Features/WP': 'Fitur/WP',
    News: 'Berita',
    Blog: 'Blog',
    Grants: 'Hibah',
    'Brand Book': 'Buku Merek',
    Presentation: 'Presentasi',
    Info: 'Info',
    Mission: 'Misi',
    Security: 'Keamanan',
    Earning: 'Produktif',
    Technology: 'Teknologi',
    Speed: 'Kecepatan',
    'Trading volume': 'Volume perdagangan',
    Liquidity: 'Likuiditas',
    buy: 'membeli',
    sell: 'menjual',
    Tools: 'Alat',
    Blockchain: 'Blockchain',
    Wallet: 'Dompet',
    'Source code': 'Kode sumber',
    Nodes: 'Node',
    'For organizators': 'Untuk penyelenggara',
    Help: 'Tolong',
    FAQ: 'FAQ',
    Guide: 'Panduan',
    'Contact us': 'Hubungi kami',
    Staking: 'Staking',
    Balance: 'Keseimbangan',
    Address: 'Alamat',
    'Block time': 'Blokir waktu',
    'Transactions in block': 'Transaksi di blok',
    'Transactions per min': 'Transaksi per menit',
    'Transactions per hour': 'Transaksi per jam',
    'Daily transactions': 'Transaksi harian',
    Blocks: 'Blok',
    Transactions: 'Transaksi',
    Price: 'Tentu saja',
    GitLab: 'GitLab',
    Addresses: 'Alamat',
    'Total coins': 'Jumlah koin',
    'In structures': 'En estructuras',
    'Initial Emission': 'Emisi Awal',
    'Staking Percentage': 'Persentase Staking',
    'Number of Structures': 'Jumlah Struktur',
    More: 'Lebih',
    'Total transactions': 'Total transaksi',
    'Block number': 'Blokir nomor',
    'Trading Volume UMI': 'Perdagangan UMI',
    Capitalization: 'Kapitalisasi',
    'Transactions per second': 'Transaksi per detik',
    Now: 'Sekarang',
    Maximum: 'Maksimum',
    'Top 100 richest addresses': '100 alamat terkaya teratas',
    'Active addresses in 24 hours': 'Alamat aktif dalam 24 jam',
    'Number of UMI addresses': 'Jumlah alamat UMI',
    'Number of addresses in structures': 'Jumlah alamat dalam struktur',
    'Genesis address': 'Alamat Genesis',
    'Number of blocks': 'Jumlah blok',
    'Last block time': 'Waktu blok terakhir',
    'Since the last block has passed': 'Sejak blok terakhir telah berlalu',
    'Transactions in block (24 hours)': 'Transaksi di blok (24 jam)',
    'Active addresses (24 hours)': 'Alamat aktif (24 jam)',
    'Blocks per hour (24 hours)': 'Blok per jam (24 jam)',
    Timestamp: 'Stempel waktu',
    'The main GitLab with documentation and SDK': 'GitLab utama dengan dokumentasi dan SDK',
    Stars: 'Bintang',
    'Last update': 'Pembaharuan Terakhir',
    Repositories: 'Repositori',
    Followers: 'Pengikut',
    'The price of the dollar in UMI': 'Harga dolar di UMI',
    'Trading volume on exchanges in 24 hours': 'Volume perdagangan di bursa dalam 24 jam',
    'Maximum price for 24 hours': 'Harga maksimal selama 24 jam',
    'Minimum price for 24 hours': 'Harga minimum selama 24 jam',
    'P2P trading volume on exchanges in 24 hours': 'Volume perdagangan P2P di bursa dalam 24 jam',
    '24 hours transaction': 'Transaksi 24 jam',
    'Transactions per hour (average)': 'Transaksi per jam (rata-rata)',
    'Transferred in 24 hours': 'Ditransfer dalam 24 jam',
    'Transferred per hour (average)': 'Ditransfer per jam (rata-rata)',
    '100 largest transactions in the last 24 hours': '100 transaksi terbesar dalam 24 jam terakhir',
    'UMI address': 'Alamat UMI',
    'Total sent': 'Total terkirim',
    'Total received': 'Total diterima',
    All: 'Semua',
    Sent: 'Terkirim',
    Received: 'Diterima',
    'Block height': 'Tinggi balok',
    'Block not yet created': 'Blokir belum dibuat',
    'Previous block': 'Blok sebelumnya',
    'Next block': 'Blok berikutnya',
    Version: 'Versi: kapan',
    'Total amount': 'Jumlah total',
    'Total fee': 'Biaya total',
    Pools: 'Kolam renang',
    'UMI structures': 'Struktur UMI',
    'Staking per day': 'Staking per hari',
    'Staking per month': 'Staking<br>per bulan',
    'Structure site': 'Situs struktur',
    'per day': 'per hari',
    'per month': 'per bulan',
    Fee: 'Biaya',
    Coins: 'Koin',
    Members: 'Anggota',
    Wallets: 'Dompet.',
    'Structure Income': 'Pendapatan Struktur',
    'The biggest crypto-community with more than 400,000 participants.': 'Komunitas crypto terbesar dengan lebih dari 400.000 peserta.',
    'High-tech platform for automatic staking of UMI cryptocurrency up to 5669% per year.': 'Platform berteknologi tinggi untuk mempertaruhkan otomatis cryptocurrency UMI hingga 5669% per tahun.',
    Block: 'Blok',
    'Another operation': 'Operasi lain',
    'Set staking percent': 'Tetapkan persen taruhan',
    'Set bonuses percent': 'Setel bonus persen',
    'Set profit address': 'Tetapkan alamat keuntungan',
    'Set fee address': 'Tetapkan alamat biaya',
    'Activate transit address': 'Aktifkan alamat transit',
    'Deactivate transit address': 'Nonaktifkan alamat transit',
    'Create new Pool': 'Buat struktur baru',
    'Update structure': 'Perbarui struktur',
    'Profit Wallet': 'Dompet Keuntungan',
    'Fee Wallet': 'Dompet Fee',
    'Transit Wallet': 'Dompet Transit',
    'Transit Wallets': 'Dompet Transit',
    View: 'Melihat',
    'All transactions without structure rewards': 'Semua transaksi tanpa hasil struktur',
    'Current emission': 'Emisi saat ini',
    'End emission': 'Emisi akhir',
    'Trading addresses': 'Alamat perdagangan',
    'Staking addresses': 'Mematikan alamat',
    'In staking': 'Dalam memperebutkan',
    red_stage: 'Merah',
    blue_stage: 'Biru',
    green_stage: 'Hijau',
    'Staking stage': 'Menuntun panggung',
    'Staking percent': 'Mempertaruhkan persen',
    Burned: 'Dibakar',
    Burning: 'Dibakar',
    'Burned in network': 'Terbakar di jaringan',
    'Burn transactions': 'Membakar transaksi',
    'Burned total': 'Total terbakar',
    'Burned today': 'Terbakar hari ini',
    'What is UMI OneApp?': 'Apa itu UMI OneApp',
    'Profit Center': 'Pusat Pendapatan',
    'Swap and exchanges': 'Pertukaran terdesentralisasi',
    Earn: 'Menghasilkan',
    Launchpad: 'Landasan peluncuran',
    'Other solutions': 'Solusi lain.',
    Brandbook: 'Brandbook',
    'Legal Opinion': 'Pendapat hukum',
    'Minting tokens': 'Minting token',
    'Mint NFT': 'Seperti NFT',
    Name: 'Nama',
    Description: 'Keterangan',
    Author: 'Pengarang',
    'Content Type': 'Jenis konten',
    'NFT collection': 'Koleksi NFT',
    'No meta data': 'Tidak ada data meta',
    "Preview did't load": 'Pratinjau tidak memuat',
    'Custom NFT content': 'Konten NFT khusus',
    'Transfer NFT': 'Transfer NFT'
  }
}
