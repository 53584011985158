import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/explorer'

Vue.use(Vuex)

// const isMainnet = window.location.href.indexOf('testnet') === -1 && window.location.href.indexOf('localhost') === -1
// const API = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8080/api' : `https://stats.umi.top/proxy${isMainnet > -1 ? '-testnet' : ''}/api`

export default new Vuex.Store({
  state: {
    locale: localStorage.getItem('lang') || 'en',
    currency: localStorage.getItem('currency') || 'USD',
    cookie: localStorage.getItem('cookieAccepted') || false,
    stats: {
      status: {
        coins: 0,
        staking: 0,
        percent: 0,
        structures_count: 1
      },
      blocks: {
        blocks: 0,
        seconds_between_blocks: 0,
        transactions_in_last_block: 0,
        blocks_per_hour: 0,
        created_at: new Date(),
        transactions_per_block: 0,
        active_addresses: 0
      },
      transactions: {
        count: 0,
        per_day: 0,
        per_hour: 0,
        per_min: 0,
        per_second: 0,
        value_per_day: 0,
        value_per_hour: 0,
        top_100_value_per_day: 0
      },
      addresses: {
        top: null,
        top_value: 0,
        active: 0
      },
      volume_all: 0,
      volume_p2p: 0,
      volume_exchange: 0,
      prices: {
        high24hr: [],
        low24hr: []
      },
      liquid: {
        bids: 0,
        asks: 0
      },
      p2p_liquid: {
        bids: 0,
        asks: 0
      },
      exchange_liquid: {
        bids: 0,
        asks: 0
      },
    },
    rod: {
      stats: {
        status: {
          coins: 0,
          exchange: 0,
          traiding: 0,
          staking: 0,
          percent: 0,
          structures_count: 1,
          trading_addressses: 0,
          staking_addresses: 0
        },
        blocks: {
          blocks: 0,
          seconds_between_blocks: 0,
          transactions_in_last_block: 0,
          blocks_per_hour: 0,
          created_at: new Date(),
          transactions_per_block: 0,
          active_addresses: 0
        },
        transactions: {
          count: 0,
          per_day: 0,
          per_hour: 0,
          per_min: 0,
          per_second: 0,
          value_per_day: 0,
          value_per_hour: 0,
          top_100_value_per_day: 0
        },
        addresses: {
          top: null,
          top_value: 0,
          active: 0
        },
        volume_all: 0,
        volume_p2p: 0,
        volume_exchange: 0,
        prices: {
          high24hr: [],
          low24hr: []
        },
        liquid: {
          bids: 0,
          asks: 0
        },
        p2p_liquid: {
          bids: 0,
          asks: 0
        },
        exchange_liquid: {
          bids: 0,
          asks: 0
        },
      }
    },
    glz: {
      stats: {
        status: {
          coins: 0,
          exchange: 0,
          traiding: 0,
          staking: 0,
          percent: 0,
          structures_count: 1,
          trading_addressses: 0,
          staking_addresses: 0
        },
        blocks: {
          blocks: 0,
          seconds_between_blocks: 0,
          transactions_in_last_block: 0,
          blocks_per_hour: 0,
          created_at: new Date(),
          transactions_per_block: 0,
          active_addresses: 0
        },
        transactions: {
          count: 0,
          per_day: 0,
          per_hour: 0,
          per_min: 0,
          per_second: 0,
          value_per_day: 0,
          value_per_hour: 0,
          top_100_value_per_day: 0
        },
        addresses: {
          top: null,
          top_value: 0,
          active: 0
        },
        volume_all: 0,
        volume_p2p: 0,
        volume_exchange: 0,
        prices: {
          high24hr: [],
          low24hr: []
        },
        liquid: {
          bids: 0,
          asks: 0
        },
        p2p_liquid: {
          bids: 0,
          asks: 0
        },
        exchange_liquid: {
          bids: 0,
          asks: 0
        },
      }
    },
    courses: {
      USD: {
        value: 1,
        limit: 2
      },
      BTC: {
        value: 0,
        limit: 8
      },
      RUB: {
        value: 0,
        limit: 2
      },
      EUR: {
        value: 0,
        limit: 2
      }
    },
    rodRates: {
      RUB: 0,
      USD: 0,
      EUR: 0,
      UAH: 0,
      BTC: 0
    },
    glzRates: {
      RUB: 0,
      USD: 0,
      EUR: 0,
      UAH: 0,
      BTC: 0
    },
    umiRates: {
      RUB: 0,
      USD: 0,
      EUR: 0,
      UAH: 0,
      BTC: 0
    },
    tokens: {},
    structures: []
  },
  getters: {
    locale: state => state.locale,
    course: state =>
      state.courses
        ? state.courses[state.currency]
          ? state.courses[state.currency].value
          : 0
        : 0,
    decimal: state =>
      state.courses
        ? state.courses[state.currency]
          ? state.courses[state.currency].limit
          : 2
        : 2,
    courses: state => state.courses,
    currency: state => state.currency || 'USD',
    currencies: state => Object.keys(state.courses) || null,
    rates: state => state.courses,
    tokens: state => state.tokens,
    cookie: state => state.cookie,
    stats: state => state.stats,
    structures: state => state.structures,
    rod: state => state.rod,
    api: () => {
      let API = '/'
      if (window.location.hostname === 'localhost') {
        API = 'https://stats.umi.top/proxy/'
        // API = 'http://127.0.0.1:8080/'
      } else {
        switch (localStorage.getItem('network')) {
          case 'testnet':
            // API = 'https://testnet.umi.top/'
            API = 'https://stats.umi.top/proxy-testnet/'
            break
          case 'local':
            API = '/'
            break
          default:
            // API = 'https://mainnet.umi.top/'
            API = 'https://stats.umi.top/proxy/'
        }
      }
      API += 'api/'

      return API
    }
  },
  mutations: {
    setLocale: (state, locale) => {
      localStorage.setItem('lang', locale)
      state.locale = locale
    },
    setCurrency: (state, value) => {
      localStorage.setItem('currency', value)
      state.currency = value
    },
    setCookie: state => {
      localStorage.setItem('cookieAccepted', true)
      state.cookie = true
    },
    setBlocksStats: (state, payload) => {
      state.stats.blocks = payload
    },
    setRodBlocksStats: (state, payload) => {
      state.rod.stats.blocks = payload
    },
    setRodStats: (state, payload) => {
      state.rod.stats.blocks = payload
    },
    setTransactionsStats: (state, payload) => {
      state.stats.transactions = payload
    },
    setRodTransactionsStats: (state, payload) => {
      state.rod.stats.transactions = payload
    },
    setGlizeTransactionsStats: (state, payload) => {
      state.glz.stats.transactions = payload
    },
    setStructures (state, payload) {
      state.structures = payload
    },
    setAddressStats: (state, payload) => {
      state.stats.addresses = payload
    },
    setStatusStats: (state, payload) => {
      state.stats.status = payload
    },
    setRodStatusStats: (state, payload) => {
      state.rod.stats.status = payload
    },
    setVolumesStats: (state, payload) => {
      state.stats.volume_all = parseFloat(payload.totals.all * 100)
      state.stats.volume_p2p = parseFloat(payload.summary.trades * 100)
      state.stats.volume_exchange = parseFloat(payload.summary.exchange * 100)
    },
    setRodVolumesStats: (state, payload) => {
      state.rod.stats.volume_all = parseFloat(payload.totals.all * 100)
      state.rod.stats.volume_p2p = parseFloat(payload.summary.trades * 100)
      state.rod.stats.volume_exchange = parseFloat(payload.summary.exchange * 100)
    },
    setGlizeVolumesStats: (state, payload) => {
      state.glz.stats.volume_all = parseFloat(payload.totals.all * 100)
      state.glz.stats.volume_p2p = parseFloat(payload.summary.trades * 100)
      state.glz.stats.volume_exchange = parseFloat(payload.summary.exchange * 100)
    },
    setLiquidStats: (state, payload) => {
      state.stats.liquid.asks = payload.totals.all.asks
      state.stats.liquid.bids = payload.totals.all.bids

      state.stats.p2p_liquid.asks = payload.summary.trades.asks
      state.stats.p2p_liquid.bids = payload.summary.trades.bids
      
      state.stats.exchange_liquid.asks = Object.entries(payload.summary.bibox.asks).reduce((acc, cv) => ({
        ...acc,
        [cv[0]]: (+acc[cv[0]] || 0) + (+cv[1] || 0)
      }), payload.summary.exchange.asks)
      state.stats.exchange_liquid.bids = Object.entries(payload.summary.bibox.bids).reduce((acc, cv) => ({
        ...acc,
        [cv[0]]: (+acc[cv[0]] || 0) + (+cv[1] || 0)
      }), payload.summary.exchange.bids)

      // state.stats.exchange_liquid.bids = payload.summary.exchange.bids + 
    },
    setRodLiquidStats: (state, payload) => {
      state.rod.stats.liquid.asks = payload.totals.all.asks
      state.rod.stats.liquid.bids = payload.totals.all.bids

      state.rod.stats.p2p_liquid.asks = payload.summary.trades.asks
      state.rod.stats.p2p_liquid.bids = payload.summary.trades.bids
      
      state.rod.stats.exchange_liquid.asks = payload.summary.exchange.asks
      state.rod.stats.exchange_liquid.bids = payload.summary.exchange.bids
    },
    setGlizeLiquidStats: (state, payload) => {
      state.glz.stats.liquid.asks = payload.totals.all.asks
      state.glz.stats.liquid.bids = payload.totals.all.bids

      state.glz.stats.p2p_liquid.asks = payload.summary.trades.asks
      state.glz.stats.p2p_liquid.bids = payload.summary.trades.bids

      state.glz.stats.exchange_liquid.asks = payload.summary.exchange.asks
      state.glz.stats.exchange_liquid.bids = payload.summary.exchange.bids
    },
    setRates: (state, payload) => {
      state.courses = payload.data
      state.tokens = payload.tokens
      state.tokens.GLS = state.tokens.GLZ
      state.tokens.NFT = {
        value: 0,
        limit: 4
      }
      state.tokens.UMI = {
        value: 1,
        limit: 4
      }
    },
    setRodRates: (state, payload) => state.rodRates = payload,
    setGlizeRates: (state, payload) => state.glzRates = payload,
    setUmiRates: (state, payload) => state.umiRates = payload
  },
  actions: {
    changeLocale: ({ commit }, locale) => commit('setLocale', locale),
    changeCurrency: ({ commit }, value) => commit('setCurrency', value),
    getStats: ({ commit }) => {
      api.getBlocksStats().then(res => commit('setBlocksStats', res.data.data))
      api.getTransactionsStats().then(res => commit('setTransactionsStats', res.data.data))
      api.getVolumes().then(res => commit('setVolumesStats', res.data.data))
      api.getLiquid().then(res => commit('setLiquidStats', res.data.data))
      api.getStatus().then(res => commit('setStatusStats', res.data.data))
    },
    getRodStats: async ({ commit }) => {
      api.getBlocksStats().then(res => commit('setRodBlocksStats', res.data.data))
      api.getRodTransactionsStats().then(res => commit('setRodTransactionsStats', res.data.data))
      api.getRodVolumes().then(res => commit('setRodVolumesStats', res.data.data))
      api.getRodLiquid().then(res => commit('setRodLiquidStats', res.data.data))
      api.getRodStatus().then(res => commit('setRodStatusStats', res.data.data))
    },
    getGlizeStats: async ({ commit }) => {
      // api.getBlocksStats().then(res => commit('setRodBlocksStats', res.data.data))
      api.getGlizeTransactionsStats().then(res => commit('setGlizeTransactionsStats', res.data.data))
      api.getGlizeVolumes().then(res => commit('setGlizeVolumesStats', res.data.data))
      api.getGlizeLiquid().then(res => commit('setGlizeLiquidStats', res.data.data))
      // api.getRodStatus().then(res => commit('setRodStatusStats', res.data.data))
    },
    getStructures: ({ commit }) => {
      api.getStructures().then(res => commit('setStructures', res.data.data))
    },
    getTrades: ({ commit }) => {
      api.getTrades().then(res => commit('setTrades', res.data.data.data))
    },
    getRates: ({ commit }) => {
      api.getRates().then(res => commit('setRates', res.data))
    },
    getUmiRates: ({ commit }) => {
      fetch("https://sigen.pro/public-exchange/umi-price").then(r => r.json()).then(r => commit('setUmiRates', r))
    },
    getRodRates: ({ commit }) => {
      fetch("https://sigen.pro/public-exchange/rod-price").then(r => r.json()).then(r => commit('setRodRates', r))
    },
    getGlizeRates: ({ commit }) => {
      fetch("https://sigen.pro/public-exchange/glz-price").then(r => r.json()).then(r => commit('setGlizeRates', r))
    }
  },
  modules: {}
})
