<template lang="pug">
  .card(:class="type")
    .card-content
      .media
        .media-left(v-if="icon")
          SvgIcon.icon(:name="icon")
        .media-content
          .label.is-spaced(v-if="label")
            | {{ label }}
          .count
            template(v-if="numbers")
              span(v-for="(item, i) in numbers" :key="i")
                growing-number(
                  v-if='item.number >= 0'
                  :value='item.number'
                  :prefix='item.prefix'
                  :suffix='item.suffix'
                  :convertToFiat='item.convertToFiat'
                  :class="{ 'is-lined': item.lined }"
                )
                span.value(v-else)
                  | {{ item.value }}

            growing-number(
              v-else-if="number !== null"
              :value='number'
              :prefix='prefix'
              :suffix='suffix'
              :convertToFiat='convertToFiat'
            )
            div.timestamp(
              v-else-if="timestamp"
            )
              | {{ timestamp | moment("DD.MM.YY HH:mm:ss [GMT] Z") }}
            a.value(
              v-if="clipboard"
              v-clipboard:copy="clipboard"
              v-clipboard:success="onCopy"
            ) {{ value }}
            div.value(v-else-if="!labelLink && !linkTitle")
              | {{ value }}
            a.icon-link(
              v-if="labelLink"
              :href="labelLink"
            ) {{ value }}
            router-link(v-else-if="linkTitle" :to="linkTo") {{ linkTitle }}

</template>

<script>
import GrowingNumber from '@/components/common/GrowingNumber'

export default {
  name: 'Widget',
  components: { GrowingNumber },
  methods: {
    isNumber () {
      return this.number.isFinite()
    }
  },
  props: {
    type: {
      type: String,
      default: 'is-card-widget'
    },
    icon: {
      type: String,
      default: null
    },
    number: {
      default: null
    },
    numbers: {
      type: Array,
      default: function () {
        return null
      }
    },
    convertToFiat: {
      type: Boolean,
      default: false
    },
    timestamp: {
      default: null
    },
    value: {
      type: String,
      default: null
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    labelLink: {
      type: String,
      default: null
    },
    linkTitle: {
      type: String,
      default: null
    },
    linkTo: {
      type: String,
      default: null
    },
    clipboard: {
      type: String,
      default: null
    }
  }
}
</script>
