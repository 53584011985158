<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',

  props: {
    name: {
      type: String,
      required: true
    },

    title: {
      type: String,
      default: null
    }
  },

  computed: {
    iconPath () {
      let icon = require(`@/assets/icons/${this.name}.svg`)
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }

      return icon.url
    },

    className () {
      return 'svg-icon svg-icon--' + this.name
    }
  }
}
</script>

<style>
.icon {
  vertical-align: middle;
}
.svg-icon {
  stroke-width: 1 !important;
  width: 1.125rem;
  height: 1.125rem;
}
.svg-icon--red-check,
.svg-icon--green-check {
  height: 0.75rem;
}
.svg-icon--red-arrow,
.svg-icon--green-arrow {
  height: 0.5625rem;
}
.button.is-primary.is-small {
  min-width: 1.825rem;
}
.button.is-primary.is-small .svg-icon {
  width: 1rem;
  height: 1rem;
}
</style>
