<template lang="pug">
span {{ prefix }}{{ newValueFormatted }}{{ newSuffix }}
</template>

<script>
import numeral from 'numeral'

export default {
  name: 'GrowingNumber',
  props: {
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    value: {
      default: 0
    },
    duration: {
      type: Number,
      default: 500
    },
    convertToFiat: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newValue: 0,
      step: 0
    }
  },
  computed: {
    newValueFormatted () {
      if (['UMI', 'GLZ', 'ROD', 'NFT'].includes(this.suffix)) { return this.toUmi(this.newValue) }
      if (this.convertToFiat) { return this.pretty(this.newValue, (this.suffix === null), this.rounded) }
      if (this.suffix === '%') { return numeral(this.newValue).format('0,0') }
      return this.newValue < 1000
        ? this.newValue
        : numeral(this.newValue).format('0,0')
    },
    newSuffix () {
      if (this.suffix) { return (this.suffix.length === 1 || this.suffix.indexOf('%') > -1) ? this.suffix : ' ' + this.suffix }
      return null
    }
  },
  mounted () {
    this.growInit()
  },
  methods: {
    growInit () {
      const m = this.value / (this.duration / 25)
      this.grow(m)
    },
    grow (m) {
      const v = Math.ceil(this.newValue + m)
      if (v > this.value) {
        this.newValue = this.value
        return false
      }

      this.newValue = v
      setTimeout(() => {
        this.grow(m)
      }, 25)
    }
  },
  watch: {
    value () {
      this.growInit()
    }
  }
}
</script>

<style scoped>
  span {
    white-space: nowrap;
    word-break: normal;
  }
</style>
