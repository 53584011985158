<template lang="pug">
  .columns.is-multiline.widgets.is-2.is-flex-mobile
    .column(
      v-for="(item, i) in stats"
      :key="i"
      :class="item.customClass ? item.customClass : ''"
    )
      Widget(
        type="small"
        :icon="item.icon"
        :label="item.label"
        :labelLink="item.labelLink"
        :number="item.number"
        :numbers="item.numbers"
        :suffix="item.suffix"
        :linkTitle="item.linkTitle"
        :linkTo="item.linkTo"
        :timestamp="item.timestamp"
        :value="item.value"
        :clipboard="item.clipboard"
      )
    slot
</template>

<script>
import Widget from '@/components/common/Widget'
export default {
  name: 'Widgets',
  components: { Widget },
  methods: {},
  props: {
    stats: {
      type: Array,
      default: null
    },
    customClass: {
      type: String,
      default: null
    }
  }
}
</script>
