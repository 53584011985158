<template lang="pug">
  .modal.is-active
    .modal-content
      SvgIcon.icon(name="cookie")
      p {{ t('We use cookies to provide you the most comfortable use of the site.') }}
        br
        span(v-html="t('Please see our <a target=\"_blank\" href=\"https://umi.top/privacy\">Privacy Policy</a> for more information.')")
      .buttons.is-centered
        .button.is-primary(
          @click="acceptCookie"
          v-translate
        ) I accept
</template>

<script>
export default {
  name: 'Cookie',
  methods: {
    acceptCookie () {
      this.$store.commit('setCookie')
    }
  }
}
</script>

<style scoped lang="scss">
  .modal {
    .modal-background {
      background: none;
      backdrop-filter: none;
    }
    .modal-content {
      max-width: 20rem;
      padding-bottom: 2rem;
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      .icon {
        display: block;
        width: 4rem;
        height: 4rem;
        margin: 0 auto 1rem;
      }
      p {
        margin-bottom: 1.5rem;
        color: #ACB1BE;
        font-size: 0.825rem;
      }
    }
  }
</style>
