<template lang="pug">
  header#header.navbar.is-transparent(v-click-outside="closeNavbar")
    .navbar-brand(ref="navbar")
      a.navbar-item(:href="link" target="_blank")
        SvgIcon.logo(
          name="logo"
        )
      span.navbar-burger.burger(ref="burger" data-target='navbarMenu')
        span
        span
        span

    #navbarMenu.navbar-menu(ref="navbarMenu")
      .navbar-start
        //- .navbar-item.has-dropdown.with-rows.is-hoverable(:class="{'is-invisible': !course}")
        .navbar-item.has-dropdown.with-rows.is-hoverable
          .top.with-hover(v-translate) Price
          .bottom
            //- .navbar-link
            //- .course
              span.has-text-weight-bold 1 ROD
              | = 0.00000
              span.is-paddingless.has-text-green 100
              span BTC
            .navbar-link.course
              span.has-text-weight-bold 1 ROD
              | = {{ course }}
              span.with-hover {{ currency }}

          .navbar-dropdown.is-left
            a.navbar-item(
              v-for="(value, key) in rodRates"
              :key="key"
              :class="{ 'is-active is-hidden-mobile': currency == key }"
              @click="changeCurrency(key)"
              ) 1 ROD = {{ value }}{{ value.sign }} ({{ key }})
            //- a.navbar-item(
              v-for="(value, key) in rates"
              :key="key"
              :class="{ 'is-active is-hidden-mobile': currency == key }"
              @click="changeCurrency(key)"
              ) 1 ROD = {{ value.value }}{{ value.sign }} ({{ key }})

      .navbar-end
        .navbar-item.with-rows.has-dropdown.is-hoverable
          .top.with-hover 01
          .bottom
            .navbar-link.with-hover(v-translate) About UMI
          .navbar-dropdown.is-boxed
            .columns
              .column.is-12-touch
                a.navbar-item(:href="link + 'blockchain'" target="_blank" v-translate) Technology
                a.navbar-item(:href="link + 'mission'" target="_blank" v-translate) Mission
              .column.is-12-touch
                a.navbar-item(:href="link + 'whitepaper'" target="_blank" v-translate) Whitepaper
                a.navbar-item(:href="link + 'brandbook'" target="_blank" v-translate) Brandbook

        .navbar-item.with-rows.has-dropdown.is-hoverable
          .top.with-hover 02
          .bottom
            .navbar-link.with-hover(v-translate) OneApp
          .navbar-dropdown.is-boxed
            .columns
              .column.is-12-touch
                a.navbar-item(:href="link + 'about'" target="_blank" v-translate) What is UMI OneApp?
                a.navbar-item(:href="link + 'wallet'" target="_blank" v-translate) U-Wallet
                a.navbar-item(:href="link + 'swaps'" target="_blank" v-translate) Swap and exchanges
                a.navbar-item(:href="link + 'earn'" target="_blank" v-translate) Profit Center
              .column.is-12-touch
                a.navbar-item(:href="link + 'dao'" target="_blank" v-translate) DAO
                a.navbar-item(:href="link + 'nft'" target="_blank" v-translate) NFT
                a.navbar-item(:href="link + 'launchpad'" target="_blank" v-translate) Launchpad
                a.navbar-item(:href="link + 'solutions'" target="_blank" v-translate) Other solutions

        .navbar-item.with-rows.has-dropdown.is-hoverable
          .top.with-hover 03
          .bottom
            .navbar-link.with-hover(v-translate) Tools

          .navbar-dropdown.is-boxed
            .columns
              .column.is-12-touch
                a.navbar-item(:href="$wallet" v-translate) Wallet
                router-link.navbar-item(to="/" v-translate) Blockchain
                a.navbar-item(href="https://gitlab.com/umitop/umid" target="_blank" v-translate) Source code
              .column.is-12-touch
                a.navbar-item(:href="link + 'volumes'" target="_blank" v-translate) Trading volume
                a.navbar-item(:href="link + 'liquidity'" target="_blank" v-translate) Liquidity
                // a.navbar-item(:href="$blog" target="_blank" v-translate) Blog

        .navbar-item.with-rows.has-dropdown.is-hoverable
          .top.with-hover 04
          .bottom
            .navbar-link.with-hover(v-translate) Help

          .navbar-dropdown.is-boxed
            .columns
              .column.is-12-touch
                a.navbar-item(:href="link + 'nodes'" target="_blank" v-translate) Nodes
                a.navbar-item(:href="link + 'api'" target="_blank" v-translate) API
                a.navbar-item(:href="link + 'contacts'" target="_blank" v-translate) Contact us
            .columns
              .column.gray
                a.navbar-item(:href="$website + 'umi_legal_opinion.pdf'" target="_blank" v-translate) Legal Opinion
                a.navbar-item(:href="link + 'privacy'" target="_blank" v-translate) Privacy Policy
                a.navbar-item(:href="link + 'terms'" target="_blank" v-translate) Terms and Conditions

        .navbar-item
          .buttons
            a.button.is-primary(:href="$wallet") {{ t('Wallet') }}

        .navbar-item
          .buttons
            a.button.is-success.lowercased(href="https://app.umi.top/" target="_blank") OneApp

        .navbar-item.with-rows.has-dropdown.is-hoverable.langs
          .top.with-hover 05
          .bottom
            .navbar-link
              .flag-container.with-hover
                SvgIcon.flag.circled(:name="'langs/' + $langs[$translate.current].flag")

          .navbar-dropdown.is-right
            a.navbar-item(
              v-for="(lang, i) in $langs"
              :key="i"
              @click="setLang(i)"
              :class="{'router-link-active': $translate.current == i}"
            )
              .flag-container
                SvgIcon.flag(:name="'langs/' + lang.flag")
              span {{ lang.title }}

        a.navbar-item
          .search-block(@click="toggleSearch" v-click-outside="closeEvent" ref="searchBlock")
            input.search-field(
              ref="search"
              v-model.trim="keyword"
              @change="find()"
              @keyup="find()"
              @keydown="find()"
              @paste="find()"
              :placeholder="t('Search for address, tx, block')"
            )
            .icon(@click="find()")
              SvgIcon.icon.search-icon(name="magnify")

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import '@/scss/main.scss'
const fetch = require('node-fetch')

export default {
  name: 'Header',
  data () {
    return {
      keyword: null
    }
  },
  computed: {
    ...mapGetters(['currency', 'rates']),
    ...mapState(['rodRates']),
    course () {
      if (!this.rodRates[this.currency]) this.changeCurrency('USD')

      return this.rodRates[this.currency]
    },
    link () {
      return (
        this.$website +
        (this.$translate.current !== 'en' ? this.$translate.current + '/' : '')
      )
    }
  },
  methods: {
    ...mapActions(['logout', 'changeCurrency', 'changeLocale']),
    setLang (lang) {
      this.changeLocale(lang)
      this.$translate.setLang(lang)
      this.$moment.locale(lang === 'zh' ? 'zh-cn' : lang)
    },
    closeNavbar () {
      this.$refs.navbar.classList.remove('is-opened')
      this.$refs.navbarMenu.classList.remove('is-active')
      this.$refs.burger.classList.remove('is-active')
    },
    closeEvent () {
      this.$refs.search.style.visibility = 'hidden'
      this.$refs.searchBlock.classList.remove('is-active')
    },
    toggleSearch () {
      this.$refs.searchBlock.classList.add('is-active')
      this.$refs.search.style.visibility = 'visible'
      this.$refs.search.focus()
    },
    find () {
      if (this.keyword) {
        this.keyword = this.keyword
          .replace(this.$blockchain, '')
          .replace('address/', '')
          .replace('transaction/', '')
      }
      if (this.keyword && (this.keyword.trim().length === 64 || this.keyword.trim().length === 62 || parseInt(this.keyword.trim()) > 0)) {
        fetch((window.location.href.indexOf('testnet') > -1 ? process.env.VUE_APP_STATS_TESTNET_URL : process.env.VUE_APP_STATS_URL) + '/search/' + this.keyword)
          .then(res => res.json())
          .then(json => {
            if (json.data) window.location.href = json.data
          })
          .catch(e => console.log(e))
      }
    }
  },
  mounted () {
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-burger'),
      0
    )

    const $navbarItems = Array.prototype.slice.call(
      document.querySelectorAll('.navbar-item.has-dropdown'),
      0
    )

    if ($navbarItems.length > 0 && window.innerWidth <= 1024) {
      // Add a click event on each of them
      $navbarItems.forEach(el => {
        el.addEventListener('click', (event) => {
          el.classList.toggle('is-active')
        })
      })
    }

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target
          const $target = document.getElementById(target)
          const $navbar = document.querySelectorAll('.navbar-brand')[0]
          const $search = document.querySelectorAll('.search-block')[0]
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active')
          $target.classList.toggle('is-active')
          $navbar.classList.toggle('is-opened')
          $search.classList.toggle('is-active')
          this.$refs.search.focus()
        })
      })
    }
  },
  watch: {
    '$route.query': {
      handler: function (value) {
        this.keyword = value.search || null
        this.find()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
$green: #57e426;
header.navbar {
  .lowercased {
    text-transform: none;
  }
  .is-opened {
    background-color: #191D26;
  }
  height: 4rem;
  background-color: transparent;
  a:not(.button),
  .navbar-dropdown a.navbar-item:visited,
  .navbar-dropdown a.navbar-item:active,
  .navbar-dropdown a.navbar-item {
    background: transparent;
    color: #fff;
    &:hover {
      color: $green;
    }
    &.router-link-active,
    &.is-active {
      color: $green;
      .flag {
        opacity: 0.5;
      }
    }
  }
  .logo {
    height: 2.5rem;
    width: 5.75rem;
    margin: 0 0.75rem 0;
  }
  .course {
    span:first-child {
      color: $green;
    }
    span {
      padding-right: 0.325rem;
      &:not(:first-child) {
        padding-left: 0.125rem;
      }
    }
  }
  .navbar-item,
  .navbar-link {
    color: #fff;
    &.has-dropdown {
      padding: 0.5rem 0.75rem;
      .navbar-link {
        padding-left: 0;
        padding-right: 1.5rem;
      }
      .navbar-link:not(.is-arrowless)::after {
        border-color: #fff;
        border-width: 1px;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 0;
        margin-top: -0.3125rem;
        right: 0.5rem;
      }
    }
  }
  .with-rows {
    flex-direction: column;
    align-items: baseline;
    align-self: center;
    .top {
      font-family: "PT Mono";
      color: #fff;
      opacity: 0.5;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    .bottom {
      .navbar-link {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
      }
    }
    &:hover {
      .with-hover {
        color: $green;
      }
      .flag-container.with-hover {
        border-color: $green;
      }
      .navbar-link:not(.is-arrowless)::after {
        border-color: $green;
        transform: rotate(-225deg);
        margin-top: -0.1rem;
      }
    }
  }
  .navbar-dropdown {
    background: #1e232e;
    border: 0.125rem solid #262b35;
    box-sizing: border-box;
    border-radius: 0.25rem;
    box-shadow: 0 1.5rem 2.5rem rgba(76, 171, 43, 0.15),
      0 1.5rem 2.5rem rgba(26, 26, 26, 0.16);
    padding: 0.75rem 0rem;
    &.is-left {
      left: 0.75rem;
    }
    text-transform: uppercase;
    a.navbar-item {
      padding: 0.825rem 1.5rem;
    }
    .gray {
      background-color: #262b35;
      text-transform: none;
      color: #acb1be;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
      overflow: hidden;
      .navbar-item {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }
    }
    .column {
      &:first-child {
        a.navbar-item {
          padding-right: 0.25rem;
        }
      }
      &:not(:first-child) {
        a.navbar-item {
          padding-left: 0;
        }
      }
      // a.navbar-item:last-child {
      //   padding-right: 0.25rem;
      // }
    }
    &::after {
      content: "";
      position: absolute;
      left: -0.125rem;
      right: -0.125rem;
      height: 0.1875rem;
      bottom: -0.125rem;
      background-color: $green;
      border-radius: 0.25rem 0.25rem 0 0;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .flag-container {
      margin-right: 1rem;
    }
  }
  .button {
    font-size: 0.825rem;
    height: 2.5rem;
  }
  .flag-container {
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.0625rem solid transparent;
    .flag {
      position: absolute;
      width: 1.875rem;
      height: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .search-block {
    position: relative;
    width: 2.5rem;
    text-align: center;
    .icon {
      width: 1.125rem;
      height: 1.125rem;
      z-index: 5;
      color: #fff;
      &:hover {
        opacity: 0.85;
      }
    }
    .search-field {
      position: absolute;
      visibility: hidden;
      right: -0.25rem;
      top: 0;
      width: 31.875rem;
      bottom: 0;
      height: 3rem;
      background: #1E232E;
      border-radius: 2px;
      padding: 0;
      margin: auto 0;
      border: 1px solid #3B434F;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;
      outline: none;
      color: #f5f3f5;
      font-size: 1rem;
      padding: 0.5rem 2.5rem 0.5rem 1rem;
      &::placeholder {
        color: #6B7381;
      }
      &:-ms-input-placeholder {
        color: #6B7381;
      }
      &::-ms-input-placeholder {
        color: #6b7381;
      }
    }
    &.is-active {
      .colored-block {
        visibility: visible;
      }
      .icon {
        color: $green;
        width: 0.875rem;
        height: 0.875rem;
      }
      ::after {
        content: "";
        position: absolute;
        right: 0.25rem;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 2rem;
        height: 2rem;
        background: #262b35;
        border-radius: 4px;
      }
    }
  }
  .navbar-burger {
    color: #ffffff;
  }
  .navbar-menu.is-active {
    background-color: #191D26;
    box-shadow: 0 10rem 30rem #191e26;
    .button {
      margin: 1rem auto;
      width: 20rem;
      font-size: 0.875rem;
      padding: 1rem;
      height: 3rem;
    }
    .columns {
      flex-wrap: wrap;
    }
    .column.is-12-touch:first-child {
      padding-bottom: 0;
    }
    .column.is-12-touch:last-child {
      padding-top: 0;
    }
    .column.gray {
      background-color: transparent;
    }
    .navbar-item {
      background-color: transparent;
      text-align: center;
      font-size: 1.125rem;
      .top {
        display: none;
      }
      .bottom a,
      .navbar-link {
        padding-right: 0;
        font-size: 1.5rem;
        //&:after {
          // display: none;
        //}
      }
    }
    .navbar-dropdown {
      display: none;
      box-shadow: none;
      background-color: transparent;
      border: none;
      &:after {
        display: none;
      }
      a.navbar-item {
        padding: 0.25rem 0.75rem;
      }
      .column:not(:first-child) {
        a.navbar-item {
          padding-left: 0.825rem;
        }
      }
      .gray {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .has-dropdown.is-active {
      .navbar-dropdown {
        display: block;
      }
    }
    .langs {
      .top,
      .bottom {
        display: none;
      }
      .navbar-dropdown {
        display: block;
        text-align: center;
        .navbar-item {
          display: inline-block;
          text-align: left;
          .flag-container {
            margin-right: 0;
            width: 2rem;
            height: 2rem;
            .flag {
              width: 2.5rem;
            }
          }
          &.router-link-active .flag-container {
            border: 1px solid $green;
          }
          &.router-link-active .flag {
            opacity: 1;
          }
          span {
            display: none;
          }
        }
      }
    }
    .search-block {
      width: auto;
      margin: 0 auto;
      .search-field {
        visibility: visible !important;
        position: relative;
        margin: 0.75rem 0;
        padding: 0.5rem 1rem;
        left: auto;
        right: auto;
        width: 80%;
      }
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;
        vertical-align: middle;
      }
      ::after {
        content: none;
      }
    }
  }
  .navbar-link:hover {
    background: transparent !important;
  }
}

.has-text-green {
  color: $green;
}
</style>
